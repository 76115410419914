import { GetServerSideProps } from 'next';
import { NoodlePage } from '@typings/NextJS';
import CreatorBySlug from '@lib/CreatorBySlug';
import handlePageRequest from 'helpers/handlePageRequest';
import ApiError from '@helpers/ApiError';
import InternalNotFoundError from '@helpers/InternalNotFoundError';
import { getCreatorProducts } from '@tsClient';
import generateSEO from '@helpers/seo';
import CreatorEditLayout from '@layouts/CreatorEditLayout';
import logger from '@helpers/logger/server';
import { usePageReloader } from '@hooks';

type PropsForPage = {
  creatorSlug: string;
  creatorData: Parameters<typeof CreatorBySlug>[0]['data'];
};

type PropsFromSSR = PropsForPage;

type Params = {
  creatorSlug: string;
};

const CreatorBySlugPage: NoodlePage<PropsForPage> = ({ creatorData, creatorSlug }) => {
  const hasData = usePageReloader({
    hasData: Boolean(creatorSlug),
    page: 'CreatorBySlugPage',
  });

  if (!hasData) {
    return null;
  }

  return <CreatorBySlug isWidget={false} data={creatorData} />;
};

CreatorBySlugPage.Layout = CreatorEditLayout;

export const getServerSideProps: GetServerSideProps<PropsFromSSR, Params> = async (context) => {
  const creatorSlug = context.params?.creatorSlug as string;
  const { error, data } = await handlePageRequest(() => getCreatorProducts({ creatorSlug, isOmitFilterByActive: true }));
  let ssrError = error;
  type Response = Awaited<ReturnType<typeof getCreatorProducts>>;
  type Creator = Omit<Response, 'artist' | 'productTypes'> & {
    artist: Response['artist'][number] | null;
    productTypes: Response['productTypes'][number]['productTypes'];
    broadcastTiers: PropsForPage['creatorData']['broadcastTiers']; // @todo - not sent by server, only needed in unused tab=Broadcasts. add to server response or remove.
  };
  let creator: Creator | null = null;
  if (!data || (data?.artist || []).length === 0) {
    ssrError = InternalNotFoundError.createForSSR();
  } else {
    try {
      creator = {
        ...data,
        artist: data.artist[0] || null,
        // @todo - not sent by server, only needed in unused tab=Broadcasts. add to server response or remove.
        broadcastTiers: (data as unknown as { broadcastTiers: NonNullable<PropsForPage['creatorData']['broadcastTiers']> }).broadcastTiers || [],

        productTypes: data.productTypes[0]?.productTypes || [],
      };
    } catch (e) {
      ssrError = ApiError.create(e).toJSON();
    }
  }

  const seo = generateSEO(context, {
    description:
      creator?.artist?.oneLiner
      || `Become a member of the community and connect with ${creator?.artist?.name || 'the creator'}!`,
    ogImageUrl: creator?.artist?.ogImage?.url,
    title: creator?.artist?.name,
  });

  logger.info(
    {
      extracted: { creatorSlug },
      hasUndefined: !creatorSlug,
      page: 'CreatorBySlugPage',
      params: context?.params || 'none',
      url: context?.req?.url || 'unknown-url',
    },
    'getServerSideProps',
  );

  return {
    props: {
      creator: creator?.artist || null,
      creatorData: creator as Creator, // Can exclude null because of InternalNotFoundError below + ErrorGate.
      creatorSlug,
      seo,
      ssrError,
    },
  };
};

export default CreatorBySlugPage;
