import React, { useEffect, useState } from 'react';
import Link from '@components/CustomLink';
import SvgNoodle from '@components/Icons/Noodle';
import ArrowCircleRight from '@components/Icons/ArrowCircleRight';
import useCanEditNoodle from '@hooks/useCanEditNoodle';
import { mixpanelTrack } from '@providers/Mixpanel';
import LogRocket from '@providers/LogRocket';
import { Layout } from '@typings/NextJS';
import isInPopoverWidget from '@helpers/isInPopoverWidget';
import isInIframe from '@helpers/isInIframe';
import { useRouter } from 'next/router';
import s from './CreatorEditLayout.module.scss';

type Props = {
  creator?: {
    id: string;
  } | null;
};

const CreatorEditLayout: Layout<Props> = ({ children, creator }) => {
  const router = useRouter();

  const [showClaim, setShowClaim] = useState(false);
  const isCreatorSlugPage = (router.pathname === '/[creatorSlug]' || router.pathname === '/[creatorSlug]/new');
  const canEdit = useCanEditNoodle({ creator });
  const trackClaimClick = (): void => {
    mixpanelTrack('Claim yours click');
  };

  useEffect(() => {
    if (!isInPopoverWidget() && !isInIframe() && !canEdit && isCreatorSlugPage) {
      setShowClaim(true);
    }
  }, [canEdit, isCreatorSlugPage]);

  return (
    <>
      {children}
      {canEdit && <LogRocket />}
      {showClaim && (
        <Link to="/claim-slug" onClick={trackClaimClick} className={s.claim}>
          <div data-translate="false">
            <SvgNoodle width={16} />
            <span>
              <strong>noodle.shop</strong>/claim-yours
            </span>
          </div>
          <ArrowCircleRight size={24} weight="fill" />
        </Link>
      )}
    </>
  );
};

export default CreatorEditLayout;
